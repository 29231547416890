/* globals _gaq */
/* eslint-disable sonarjs/no-duplicate-string, sonarjs/cognitive-complexity, sonarjs/no-redundant-jump, sonarjs/no-nested-switch */

import PersonalCapital from "./services";
import parseResponseErrors from "libs/pcap/utils/response";

var views = (PersonalCapital.views = PersonalCapital.views || {});
var utils = (PersonalCapital.utils = PersonalCapital.utils || {});
const ANIMATION_CONST = 500;
const USER_NAME_LENGTH = 50;
const PASSWORD_MIN_LENGTH = 8;
const PASSWORD_MAX_LENGTH = 64;
const TOTP_CODE_LENGTH = 6;
const ENTER_KEY = 13;
const TWO_ZERO_TWO_ERROR = 202;
const TWO_ZERO_SIX_ERROR = 206;
const THREE_TWELVE_ERROR = 312;
const THREE_TWO_ONE_ERROR = 321;
const LOGIN_PAGE_STUCK_WORKAROUND_DELAY = 1500;

//analytics
PersonalCapital.tracking = {};
PersonalCapital.tracking.googleAnalytics = {
  recordEvent: function (data) {
    try {
      _gaq.push(["_trackEvent", data.join()]);
    } catch (e) {
      return;
    }
  },
};
function fireGoogleTagManagerEvent(event, eventProps = undefined) {
  if (!window.dataLayer) {
    return;
  }
  window.dataLayer.push(Object.assign({}, { event }, eventProps));
}

//login module
/* Used in the JSP in an inline script call */
// eslint-disable-next-line no-unused-vars
let LOGIN = (views.LOGIN = views.LOGIN || {});
(function (login, $) {
  var formEmail = $("#form-email"),
    formPassword = $("#form-password"),
    signInButton = $(".js-form-password__submit-btn"),
    formCreatePassword = $("#form-createPassword"),
    formPin = $("#form-pin"),
    challengeResetPasswordEmailSent = $(".js-reset-password-email-sent"),
    formChallengeRequest = $("#form-challengeRequest"),
    formChallengeResponseEmail = $("#form-challengeResponse-email"),
    formChallengeResponsePhone = $("#form-challengeResponse-phone"),
    formChallengeResponseTotp = $("#form-challengeResponse-totp"),
    unregisteredDeviceTotp = $(".js-device-unregistered"),
    formChallengeResponseSms = $("#form-challengeResponse-sms"),
    formContactSupport = $("#form-contactSupport"),
    accountSummary = {};

  var googleAnalytics = PersonalCapital.tracking.googleAnalytics;
  const PHONE_AUTH_FAILED = 352;

  const organizationNameDashboard = EMPOWER_PERSONAL_DASHBOARD;

  login.determineState = function (data) {
    // If the user is in remember state, the login credentials are in the JSP printed variables
    // so instead of calling identifyUser to get the new password flow feature flag, we need to read it from
    // data, which is passed in the JSP JS snippet when this fn gets called
    if (data.spHeader.authLevel && data.spHeader.authLevel !== "NONE") {
      login.setForgotPasswordFeatureFlag(data.spData.allCredentials);
    } else {
      window.isForgotPasswordEnabled = undefined;
    }

    var status = (login.model.status = data.spHeader.status),
      authLevel = (login.model.authLevel = data.spHeader.authLevel),
      formData = (login.model.formData = data.spData);
    if (data.spData && data.spData.allCredentials)
      login.authType.set(data.spData.allCredentials);
    if (data.spHeader) accountSummary = data.spHeader.accountsSummary || {};

    // TOTP feature flag
    if (
      data.spData &&
      data.spData.allCredentials.some(
        (cred) => cred.name === "OOB_TOTP" && cred.status === "ACTIVE"
      )
    ) {
      $(".js-use-totp-link").show();
    } else {
      $(".js-use-totp-link").hide();
    }

    switch (status) {
      default: {
        // "NONE"
        login.showEmail({ username: window.prepopulatedEmail || "" });
        break;
      }
      case "PARTIAL_ENROLLMENT": {
        login.showRegistration(formData);
        break;
      }
      case "INACTIVE": {
        login.showRegistration(formData);
        break;
      }
      case "LOCKED": {
        // If it has the token, we should do nothing since after this fn runs we
        // validate the token and the handler will render the correct form after.
        if (window.isForgotPasswordEnabled && !LOGIN.urlParams.has("token")) {
          login.showChallengeResetPasswordEmailSent(
            "For your security, your account has been locked. Please reset your password. "
          );
        } else if (accountSummary.hasOnUs) {
          // locked password reset credential
          login.showContactSupport(
            "Password reset attempt has exceeded the daily limit, please try again tomorrow. If this is urgent, contact your advisor."
          );
        } else {
          login.showContactSupport(
            "Password reset attempt has exceeded the daily limit, please try again tomorrow."
          );
        }
        break;
      }
      case "ACTIVE": {
        switch (authLevel) {
          default: {
            // "USER_IDENTIFIED"
            login.showDeviceAuthorization();
            break;
          }
          case "DEVICE_AUTHORIZED": {
            login.showDeviceAuthorized();
            break;
          }
          case "USER_REMEMBERED": {
            login.showPinOrPassword(formData);
            break;
          }
          case "MFA_REQUIRED": {
            window.location.href = "/page/login/mfa?challengeReason=ALWAYS_MFA";
            break;
          }
          case "SESSION_AUTHENTICATED": {
            login.showDashboard(data && data.spHeader);
            break;
          }
        }
        break;
      }
      case "REQUIRES_VERIFICATION": {
        login.showRegistration(formData);
        if (window.mixpanel && window.mixpanel.track) {
          window.mixpanel.track("View Login REQUIRES_VERIFICATION Options", {
            component: "Login",
          });
        }
        break;
      }
    }
  };
  //public functions that can be overridden
  login.model = {
    status: "NONE",
    authLevel: "NONE",
    authType: "PASSWORD",
    challengeReason: "DEVICE_AUTH",
    formData: {},
  };
  login.authType = {
    set: function (allCredentials) {
      var usedCredentials = [];
      $.each(allCredentials, function (index, obj) {
        if (obj.status === "ACTIVE" || obj.status === "LOCKED")
          usedCredentials.push(obj.name);
      });
      //if credentials contains password, show password
      if ($.inArray("PASSWORD", usedCredentials) !== -1) {
        login.model.authType = "PASSWORD";
        return;
      }
      //if credentials do not contain password but contains pin, show pin
      if (
        $.inArray("PASSWORD", usedCredentials) === -1 &&
        $.inArray("PIN", usedCredentials) !== -1
      ) {
        login.model.authType = "PIN";
        return;
      }
    },
    get: function () {
      return login.model.authType;
    },
  };
  login.addDefaultPostData = function (params) {
    var p = params || {};
    if (typeof p.apiClient == "undefined") p.apiClient = "WEB";
    if (typeof p.bindDevice == "undefined") p.bindDevice = false;
    return p;
  };
  login.responseContainsErrors = function (response) {
    if (!response.spHeader) {
      //TODO:will have to check if this is a possible scenario
    }
    var errors = response.spHeader.errors;

    // Return phone authentication failure error
    const phoneAuthError =
      errors &&
      errors.filter(function (e) {
        return e.code === PHONE_AUTH_FAILED;
      });

    if (phoneAuthError && phoneAuthError.length) {
      return phoneAuthError[0];
    }

    var error =
      typeof errors === "undefined" ? undefined : errors[errors.length - 1];
    if (error && error.code === TWO_ZERO_TWO_ERROR) {
      //TODO:session is not valid
      //window.location=window.baseUrl;
    }
    return error;
  };

  login.setForgotPasswordFeatureFlag = function (allCredentials) {
    window.isForgotPasswordEnabled = allCredentials?.some(
      (cred) => cred.name === "PASSWORD_RESET" && cred.status === "ACTIVE"
    );
  };

  login.phoneAuthPolling = (function () {
    var id = -1,
      interval = 3000,
      maxAttempts = 40,
      attempts = 0;
    return {
      start: function (params, callback, timeout) {
        login.phoneAuthPolling.stop(); //to be safe
        id = setInterval(function () {
          PersonalCapital.services.Login.authenticatePhoneAuth(
            params,
            callback
          );
          if (++attempts > maxAttempts) {
            timeout();
          }
        }, interval);
      },
      stop: function () {
        clearInterval(id);
        attempts = 0;
      },
    };
  })();
  login.appAuthPolling = (function () {
    //10 min, server will likely timeout before and send expired. This is handled in @startTotpPolling
    var id = -1,
      interval = 3000,
      maxAttempts = 200,
      attempts = 0;
    return {
      start: function (params, callback, timeout) {
        login.appAuthPolling.stop(); //to be safe
        id = setInterval(function () {
          PersonalCapital.services.Login.authenticateTotpAuth(params, callback);
          if (++attempts > maxAttempts) {
            timeout();
          }
        }, interval);
      },
      stop: function () {
        clearInterval(id);
        attempts = 0;
      },
    };
  })();
  login.showEmail = function (data) {
    formEmail.showForm(data);
  };
  login.showPinOrPassword = function (data) {
    if (login.authType.get() === "PIN") {
      login.showPin(data);
    } else {
      login.showPassword(data);
    }
  };
  login.showPassword = function (data) {
    data.passwd = "";
    if (typeof data.bindDevice == "undefined") data.bindDevice = false;
    formPassword.showForm(data);
    formPassword.find(".control-group.bindDevice").hide();
    formPassword.find("input[name=deviceName]").hide();
    formPassword.find(".control-group.deviceNameInput").hide();
    formPassword.find(".control-group.deviceNameLabel").show();
  };
  login.showForgotPinOrPassword = function (data) {
    if (login.authType.get() === "PIN") {
      login.showEmail(data);
      utils.setFieldValidationFlags(
        $.FORM_CURR.find("[name=username]"),
        utils.validationMessages.lockedPIN
      );
    } else {
      login.showForgotPassword();
    }
  };
  login.showForgotPassword = function () {
    login.showChallengeRequest("PWD_RESET");
  };
  login.showDeviceAuthorization = function () {
    login.showChallengeRequest("DEVICE_AUTH");
  };
  login.showPin = function (data) {
    if (data) data.pinNumber = "";
    formPin.showForm(data);
    formPin.find(".control-group.bindDevice").hide();
    formPin.find("input[name=deviceName]").hide();
    formPassword.find(".control-group.deviceNameInput").hide();
    formPin.find(".control-group.deviceNameLabel").show();
  };
  login.showChallengeResetPasswordEmailSent = function (header = "") {
    PersonalCapital.services.Login.challengeResetPassword(
      login.addDefaultPostData({}),
      function (data) {
        let templateData = {
          legend: "Forgot Password",
        };
        if (data.spHeader.success) {
          templateData.challengeDescription =
            header +
            "We sent you an email with a link to reset your password. Please check your inbox and spam folders";
        } else {
          var error = login.responseContainsErrors(data);
          templateData.challengeDescription = error.message;
        }
        challengeResetPasswordEmailSent.showForm(templateData);
      }
    );
  };
  login.showChallengeRequest = function (challengeReason) {
    if (challengeReason) {
      login.model.challengeReason = challengeReason;
    }
    var formData = {};
    fireGoogleTagManagerEvent("VIEW_DEVICE_AUTH_CHALLENGE");
    if (challengeReason === "PWD_RESET") {
      formData.legend = "Forgot Password";
      formData.challengeStep = "Step 1 of 4";
      formData.challengeDescription = "Select a method to reset your password.";
      if (window.mixpanel && window.mixpanel.track) {
        window.mixpanel.track("View Login PWD_RESET Options", {
          component: "Login",
        });
      }
    } else if (challengeReason === "DEVICE_AUTH") {
      formData.legend = `Sign in to ${organizationNameDashboard}`;
      formData.challengeDescription =
        "For your security, please choose how you would like to authorize this device.";
      if (window.mixpanel && window.mixpanel.track) {
        window.mixpanel.track("View Login DEVICE_AUTH Options", {
          component: "Login",
        });
      }
    }

    // If challenge by email is not an option for this user, hide the "Email me" option
    if (
      !window.validationOptions ||
      window.validationOptions?.indexOf("OOB_EMAIL") < 0
    ) {
      const emailMeElem = formChallengeRequest.find("[value='challengeEmail']");
      emailMeElem.hide();
    }

    formData.challengeReason = login.model.challengeReason;
    formChallengeRequest
      .find("[name=challengeType]:first")
      .attr("checked", true);
    formChallengeRequest.find("button.js-cancel").addClass(challengeReason);
    formChallengeRequest.showForm(formData);
  };
  login.showChallengeEmail = function () {
    var challengeReason = login.model.challengeReason,
      formData = {};
    formChallengeResponseEmail.find("[name='challengePhone']").show();
    if (challengeReason === "PWD_RESET") {
      formData.legend = "Forgot Password";
      formData.challengeStep = "Step 2 of 4";
      formData.challengeDescription =
        "We sent a message to your email with a code to enable you to reset your password.";
    } else if (challengeReason === "DEVICE_AUTH") {
      formData.legend = `Sign in to ${organizationNameDashboard}`;
      formData.challengeDescription =
        "We sent a message to your email with a code to enable you to register this device.";
    } else if (challengeReason === "REQUIRES_VERIFICATION") {
      formData.legend = "Activate Your Account";
      formData.challengeDescription =
        "We sent a message to your email with a code to enable you to activate your account.";
      formChallengeResponseEmail.find("[name='challengePhone']").hide();
      formChallengeResponseEmail.find("[name='challengeSMS']").hide();
      $("#contactUs").show();
    }
    formData.challengeReason = challengeReason;
    formData.code = "";
    formChallengeResponseEmail
      .find("button.js-cancel")
      .addClass(challengeReason);
    formChallengeResponseEmail.showForm(formData);
    if (window.mixpanel && window.mixpanel.track) {
      window.mixpanel.track("View Login " + challengeReason + " Email", {
        component: "Login",
      });
    }
  };
  login.showChallengeSMS = function () {
    var challengeReason = login.model.challengeReason,
      formData = {};
    formChallengeResponseSms.find("[name='challengePhone']").show();
    if (challengeReason === "PWD_RESET") {
      formData.legend = "Forgot Password";
      formData.challengeStep = "Step 2 of 4";
      formData.challengeDescription =
        "We sent a message to your phone with a code to enable you to reset your password.";
    } else if (challengeReason === "DEVICE_AUTH") {
      formData.legend = `Sign in to ${organizationNameDashboard}`;
      formData.challengeDescription =
        "We sent a message to your phone with a code to enable you to register this device.";
    }
    // If challenge by email is not an option for this user, hide the "Switch to authorize by email"
    if (
      !window.validationOptions ||
      window.validationOptions.indexOf("OOB_EMAIL") < 0
    ) {
      const switchToEmailElem = formChallengeResponseSms.find(
        "[name='challengeEmail']"
      );
      switchToEmailElem.hide();
    }

    formData.challengeReason = challengeReason;
    formData.code = "";
    formChallengeResponseSms.find("button.js-cancel").addClass(challengeReason);
    formChallengeResponseSms.showForm(formData);
    if (window.mixpanel && window.mixpanel.track) {
      window.mixpanel.track("View Login " + challengeReason + " Text", {
        component: "Login",
      });
    }
  };
  login.showChallengePhone = function () {
    var challengeReason = login.model.challengeReason,
      formData = {};
    if (challengeReason === "PWD_RESET") {
      formData.legend = "Forgot Password";
      formData.challengeStep = "Step 2 of 4";
      formData.challengeDescription =
        "We are calling your phone to reset your password.";
    } else if (challengeReason === "DEVICE_AUTH") {
      formData.legend = `Sign in to ${organizationNameDashboard}`;
      formData.challengeDescription =
        "We are calling your phone to authorize this device.";
    }

    // If challenge by email is not an option for this user, hide the "Switch to authorize by email"
    if (
      !window.validationOptions ||
      window.validationOptions.indexOf("OOB_EMAIL") < 0
    ) {
      const switchToEmailElem = formChallengeResponsePhone.find(
        "[name='challengeEmail']"
      );
      switchToEmailElem.hide();
    }

    formData.challengeReason = challengeReason;
    formChallengeResponsePhone
      .find("button.js-cancel")
      .addClass(challengeReason);
    formChallengeResponsePhone
      .find(".js-phone-challenge-polling-feedback")
      .removeClass("hide");
    formChallengeResponsePhone.find("label.controls.polling").show();
    formChallengeResponsePhone.showForm(formData);
    formChallengeResponsePhone.submit();
    if (window.mixpanel && window.mixpanel.track) {
      window.mixpanel.track("View Login " + challengeReason + " Phone", {
        component: "Login",
      });
    }
  };
  login.showChallengeTotp = function () {
    formChallengeResponseTotp.find(".js-legend, .js-enter-totp-code").show();
    formChallengeResponseTotp
      .find(".js-totp-code-container, .js-totp-submit-code")
      .hide();
    var challengeReason = login.model.challengeReason,
      formData = {};
    if (challengeReason === "PWD_RESET") {
      formData.legend = "Forgot Password";
      formData.challengeStep = "Step 2 of 4";
    } else if (challengeReason === "DEVICE_AUTH") {
      formData.legend = `Sign in to ${organizationNameDashboard}`;
    }
    formData.challengeDescription =
      "We sent a push notification to your registered device. Didn't Receive The Push?";
    formData.challengeReason = challengeReason;
    formChallengeResponseTotp
      .find("button.js-cancel")
      .addClass(challengeReason);
    formChallengeResponseTotp.showForm(formData);
    login.startTotpPolling();
    if (window.mixpanel && window.mixpanel.track) {
      window.mixpanel.track("View Login " + challengeReason + " TOTP", {
        component: "Login",
      });
    }
  };

  login.showEnterTotpCodeFields = function () {
    var formData = { challengeReason: login.model.challengeReason };
    if (formData.challengeReason === "PWD_RESET") {
      formData.legend = "Forgot Password";
      formData.challengeStep = "Step 2 of 4";
    } else if (formData.challengeReason === "DEVICE_AUTH") {
      formData.legend = `Sign in to ${organizationNameDashboard}`;
    }
    formChallengeResponseTotp
      .find("button.js-cancel")
      .addClass(formData.challengeReason);
    formChallengeResponseTotp.showForm(formData);
    formChallengeResponseTotp
      .find(".js-challenge-description")
      .text(
        `Open the ${organizationNameDashboard} app on your registered mobile device and go to Settings to find Mobile App Verification.`
      );
    formChallengeResponseTotp.find(".js-enter-totp-code").hide();
    formChallengeResponseTotp
      .find(".js-legend, .js-totp-code-container, .js-totp-submit-code")
      .show();
    formChallengeResponseTotp.find(".js-totp-code-input").val("");
    if (window.mixpanel && window.mixpanel.track) {
      window.mixpanel.track(
        "View Login " + formData.challengeReason + " TOTP Manual Entry",
        {
          component: "Login",
        }
      );
    }
  };

  login.showUnregisteredDevice = function (callback, buttonLabel) {
    unregisteredDeviceTotp.showForm();
    var continueBtn = unregisteredDeviceTotp.find(
      ".js-unregister-device-continue"
    );
    continueBtn.unbind();
    continueBtn.bind("click", callback);

    if (buttonLabel) {
      continueBtn.text(buttonLabel);
    }

    if (window.mixpanel && window.mixpanel.track) {
      window.mixpanel.track("View Login TOTP Device Unregistered", {
        component: "Login",
      });
    }
  };

  login.showCreatePassword = function () {
    var challengeReason = login.model.challengeReason,
      formData = {};
    if (challengeReason === "PWD_RESET") {
      formData.legend = "Forgot Password";
      formData.challengeStep = "Step 4 of 4";
      formData.challengeDescription = "Create a new password.";
      formCreatePassword.find("button.js-cancel").show();
    } else if (challengeReason === "PWD_CREATE") {
      formData.legend = "Create Password";
      formData.challengeStep = "";
      formData.challengeDescription = "";
      formData.flags = "Pw";
      formCreatePassword.find("button.js-cancel").hide();
    }
    formData.passwd = "";
    formData.passwd2 = "";
    formData.challengeReason = challengeReason;
    formCreatePassword.find("button.js-cancel").addClass(challengeReason);
    formCreatePassword.showForm(formData);
  };
  login.showDeviceAuthorized = function () {
    PersonalCapital.services.Login.suggestDeviceName(
      login.addDefaultPostData($(this).serializeObject()),
      function (data) {
        var error = login.responseContainsErrors(data);
        if (error) {
          //do nothing
        }
        if (data.spData && data.spData.deviceName)
          data.spHeader.deviceName = data.spData.deviceName;
        data.spHeader.bindDevice = true;
        login.showPinOrPassword(data.spHeader);
        //TODO: should be done with respect to form
        $(".control-group.bindDevice").show();
        $(".control-group.deviceNameLabel").hide();
        $("input[name=deviceName]").show();
        $(".control-group.deviceNameInput").show();
      }
    );
  };
  login.showContactSupport = function (msg) {
    if (typeof msg != "undefined") {
      formContactSupport.find('label[for="challengeDescription"]').html(msg);
    }
    formContactSupport.showForm();
  };
  login.showDashboard = function (spHeaderData) {
    //Remove localStorage before user start any activities
    if (window.localStorage.getItem("AggregationAbandon")) {
      window.localStorage.removeItem("AggregationAbandon");
    }
    if (window.localStorage.getItem("LeaveAddFi")) {
      window.localStorage.removeItem("LeaveAddFi");
    }
    spHeaderData = spHeaderData || {};
    window.userGuid = spHeaderData.userGuid;
    if (window.dataLayer) {
      window.dataLayer.push({ event: "/login" });
    }
    // Fire Google Tag Manager event
    // Suppresing 3rd party naming convention
    /* eslint-disable no-underscore-dangle */
    if (window._gaq) {
      window._gaq.push(["_trackEvent", "LogIn", "SuccessfulLogin"]);
    }
    /* eslint-enable no-underscore-dangle */
    if (window.PCAP) {
      // Fix for web_1900 to clear out any existing refreshingAccounts at login
      window.PCAP.refreshingAccounts = [];
    }
    if (window.mixpanel && window.mixpanel.track && window.mixpanel.identify) {
      window.mixpanel.identify(spHeaderData.userGuid);
      window.mixpanel.track("Login", {
        component: "Web App",
        guid: spHeaderData.userGuid,
        email: spHeaderData.username,
      });
    }
    window.location.replace(window.baseUrl + "page/login/app#/dashboard");
    // reload page in a case if user is stuck on login page after successful login
    var loginStuckWorkaroundTimer = setTimeout(function () {
      clearTimeout(loginStuckWorkaroundTimer);
      if ($(".js-form-password__submit-btn").length) {
        window.location.reload();
      }
    }, LOGIN_PAGE_STUCK_WORKAROUND_DELAY);
  };
  login.showRegistration = function (data) {
    //window.location=window.baseUrl+"page/login/registerUser";
    formEmail.showForm(data);
    utils.setFieldValidationFlags(
      $.FORM_CURR.find("[name=username]"),
      utils.validationMessages.inactiveUsername
    );
  };
  login.switchUser = function () {
    try {
      PersonalCapital.services.Login.switchUser(
        login.addDefaultPostData(),
        function (data) {
          var error = login.responseContainsErrors(data);
          if (error) {
            utils.setFormValidationFlags(
              $.FORM_CURR.find(".form-error"),
              error.message
            );
            return;
          }
          //query current session to determine login state and get latest csrf
          PersonalCapital.services.Login.validateSession(
            login.addDefaultPostData(),
            function (data) {
              var error = login.responseContainsErrors(data);
              if (error) {
                login.showEmail({ "form-error": error.message });
                return;
              }
              login.determineState(data);
            }
          );
        }
      );
      //eslint-disable-next-line no-empty
    } catch (e) {}
  };
  login.cancelRequiresVerification = function () {
    PersonalCapital.services.Login.switchUser(
      login.addDefaultPostData(),
      function () {
        window.history.back();
      }
    );
  };
  login.validateSession = function () {
    PersonalCapital.services.Login.validateSession(
      login.addDefaultPostData(),
      function (data) {
        var error = login.responseContainsErrors(data);
        if (error) {
          login.showEmail({ "form-error": error.message });
          return;
        }
        login.determineState(data);
      }
    );
  };
  login.initGoogleAnalytics = function () {
    googleAnalytics.init(window.gaApiKey, window.domainName);
  };
  login.disableSubmitButton = function (btn) {
    if (typeof btn == "undefined") {
      btn = $.FORM_CURR.find("button.btn-primary");
    }
    $(btn).attr("disabled", true).addClass("disabled");
  };
  login.enableSubmitButton = function (btn) {
    if (typeof btn == "undefined") {
      btn = $.FORM_CURR.find("button.btn-primary");
    }
    btn.attr("disabled", false).removeClass("disabled");
  };

  login.startTotpPolling = function () {
    var form = formChallengeResponseTotp;
    var params = $(form).serializeObject();
    delete params.totpCode;
    login.appAuthPolling.start(
      login.addDefaultPostData(params),
      function (data) {
        // Error handling
        var error = login.responseContainsErrors(data);

        if (error) {
          login.appAuthPolling.stop();
          utils.setFormValidationFlags(
            $.FORM_CURR.find(".form-error"),
            error.message
          );
          if (window.mixpanel && window.mixpanel.track) {
            window.mixpanel.track(
              "Submit Login " + login.model.challengeReason + " TOTP failed",
              {
                component: "Login",
                error: error.message,
              }
            );
          }
          return;
        }

        // Rejected or Expired
        if (
          data.spHeader.success &&
          data.spData &&
          data.spData.pullStatus &&
          (data.spData.pullStatus === "EXPIRED" ||
            data.spData.pullStatus === "FAILED")
        ) {
          login.appAuthPolling.stop();
          //Show select auth method + error
          login.showChallengeRequest();
          utils.setFormValidationFlags(
            $.FORM_CURR.find(".form-error"),
            data.spData.pullResultDesc
          );
          if (window.mixpanel && window.mixpanel.track) {
            window.mixpanel.track(
              "Submit Login " + login.model.challengeReason + " TOTP failed",
              {
                component: "Login",
                error: data.spData.pullResultDesc,
              }
            );
          }
          return;
        }

        //Success
        if (
          data.spHeader.success &&
          data.spData &&
          data.spData.pullStatus &&
          data.spData.pullStatus === "VERIFIED"
        ) {
          login.appAuthPolling.stop();
          if (login.model.challengeReason === "PWD_RESET") {
            login.showCreatePassword();
          } else if (login.model.challengeReason === "DEVICE_AUTH") {
            login.showDeviceAuthorized();
          }
        }
      },
      function () {
        login.appAuthPolling.stop();
        utils.setFormValidationFlags(
          $.FORM_CURR.find(".form-error"),
          "We received no response from your phone."
        );
      }
    );
    return false;
  };
  login.verifyResetToken = function (loginInitParams, token) {
    PersonalCapital.services.Login.authenticateResetPasswordToken(
      LOGIN.addDefaultPostData({ token: token }),
      function (response) {
        const errors = parseResponseErrors(undefined, response);
        if (errors) {
          $.FORM_CURR.find(".form-error").text(errors[0]);
          LOGIN.urlParams.delete("token");
        } else {
          window.validationOptions = response.spData?.verificationOptions;
          LOGIN.showForgotPassword();
        }
      }
    );
  };
  login.authenticatePassword = function () {
    // eslint-disable-next-line no-invalid-this
    if (!utils.validateForm(formPassword)) return false;
    login.disableSubmitButton();
    PersonalCapital.services.Login.authenticatePassword(
      // eslint-disable-next-line no-invalid-this
      login.addDefaultPostData(formPassword.serializeObject()),
      function (data) {
        login.enableSubmitButton();
        var error = login.responseContainsErrors(data);
        if (error) {
          // After the 4th attempt the server is unable to add details to the request.
          // Forcing the message to show below password
          var fieldName = error.details ? error.details.fieldName : "passwd";
          var field = $.FORM_CURR.find("[name=" + fieldName + "]");
          utils.setFieldValidationFlags(field, error.message);
          if (error.code === THREE_TWELVE_ERROR) field.val("");
          return;
        }
        login.determineState(data);
      }
    );
  };
  login.init = function (initData) {
    //determine initial state
    if (typeof initData == "object") {
      //if initial state is know, display the state
      login.determineState(initData);
    } else {
      //query current session to determine the login state
      login.validateSession();
    }
    //bind form submit events
    formEmail.on("submit", function () {
      // eslint-disable-next-line no-invalid-this
      if (!utils.validateForm($(this))) return false;
      login.disableSubmitButton();
      var allVerificationOptions = ["OOB_EMAIL", "OOB_SMS", "OOB_PHONE"];
      const challengeEmail = $("button[value='challengeEmail']");
      const challengeSMS = $("button[value='challengeSMS']");
      const challengePhone = $("button[value='challengePhone']");
      var challengeFormMap = {
        OOB_EMAIL: challengeEmail,
        OOB_SMS: challengeSMS,
        OOB_PHONE: challengePhone,
      };

      PersonalCapital.services.Login.identifyUser(
        // eslint-disable-next-line no-invalid-this
        login.addDefaultPostData($(this).serializeObject()),
        function (data) {
          if (!window.userGuid) {
            window.userGuid = data && data.spHeader && data.spHeader.userGuid;
          }
          login.enableSubmitButton();
          // Update exposed credentials
          window.allCredentials = data.spData?.allCredentials;
          window.validationOptions = data.spData?.verificationOptions;
          // TOTP feature flag, If TOTP is enabled show totp buttons
          if (
            window.allCredentials &&
            window.allCredentials.some(
              (cred) => cred.name === "OOB_TOTP" && cred.status === "ACTIVE"
            )
          ) {
            $(".js-use-totp-link").show();
          } else {
            $(".js-use-totp-link").hide();
          }
          // use verificationOptions field to determine the various methods of verification
          if (data.spData.verificationOptions?.length > 0) {
            allVerificationOptions.forEach((option) => {
              if (data.spData.verificationOptions.indexOf(option) < 0) {
                const verificationElement = challengeFormMap[option];
                verificationElement.hide();
              }
            });
          }
          login.setForgotPasswordFeatureFlag(window.allCredentials);

          var error = login.responseContainsErrors(data);
          if (error) {
            utils.setFormValidationFlags(
              $.FORM_CURR.find(".form-error"),
              error.message
            );
            return;
          }
          if (data.spData) {
            data.spData.username = data.spHeader.username;
            data.spData.deviceName = data.spHeader.deviceName;
          }
          login.determineState(data);
        }
      );
      return false;
    });
    // We suspect that using the form submit is overriding the dashboard redirect for some users
    // so we've changed the submit to click handler, this also means that we need to keep the
    // functionality of calling the API when the user press enter in password field
    formPassword.find('[type="password"]').keypress(function (e) {
      if (e.which === ENTER_KEY) {
        login.authenticatePassword();
      }
    });
    signInButton.on("click", login.authenticatePassword);
    var challengeType = "";
    formChallengeRequest
      .find("button[name='challengeType']")
      .off("click")
      .bind("click", function (event) {
        challengeType = $(event.currentTarget).val();
      });
    formChallengeRequest.on("submit", function () {
      if (challengeType === "challengeSMS") {
        login.disableSubmitButton();
        PersonalCapital.services.Login.challengeSMS(
          // eslint-disable-next-line no-invalid-this
          login.addDefaultPostData($(this).serializeObject()),
          function (data) {
            login.enableSubmitButton();
            var error = login.responseContainsErrors(data);
            if (error) {
              utils.setFormValidationFlags(
                $.FORM_CURR.find(".form-error"),
                error.message
              );
              return;
            }
            login.showChallengeSMS();
          }
        );
      } else if (challengeType === "challengePhone") {
        login.disableSubmitButton();
        PersonalCapital.services.Login.challengePhone(
          // eslint-disable-next-line no-invalid-this
          login.addDefaultPostData($(this).serializeObject()),
          function (data) {
            login.enableSubmitButton();
            var error = login.responseContainsErrors(data);
            if (error) {
              utils.setFormValidationFlags(
                $.FORM_CURR.find(".form-error"),
                error.message
              );
              return;
            }
            login.showChallengePhone();
          }
        );
      } else if (challengeType === "challengeEmail") {
        login.disableSubmitButton();
        PersonalCapital.services.Login.challengeEmail(
          // eslint-disable-next-line no-invalid-this
          login.addDefaultPostData($(this).serializeObject()),
          function (data) {
            login.enableSubmitButton();
            var error = login.responseContainsErrors(data);
            if (error) {
              utils.setFormValidationFlags(
                $.FORM_CURR.find(".form-error"),
                error.message
              );
              return;
            }
            login.showChallengeEmail();
          }
        );
      } else if (challengeType === "challengeApp") {
        login.disableSubmitButton();
        // eslint-disable-next-line no-invalid-this
        const params = $(this).serializeObject();
        params.challengeMethod = "TP";
        PersonalCapital.services.Login.challengeTotp(
          login.addDefaultPostData(params),
          function (data) {
            login.enableSubmitButton();
            var error = login.responseContainsErrors(data);
            if (error) {
              utils.setFormValidationFlags(
                $.FORM_CURR.find(".form-error"),
                error.message
              );
              return;
            }
            if (data.spData && data.spData.pushEnabled) {
              login.showChallengeTotp(data);
            } else {
              login.showEnterTotpCodeFields(data);
            }
          }
        );
      }
      return false;
    });
    formChallengeResponseSms.on("submit", function () {
      // eslint-disable-next-line no-invalid-this
      if (!utils.validateForm($(this))) return false;
      login.disableSubmitButton();
      PersonalCapital.services.Login.authenticateSMS(
        // eslint-disable-next-line no-invalid-this
        login.addDefaultPostData($(this).serializeObject()),
        function (data) {
          login.enableSubmitButton();
          var error = login.responseContainsErrors(data);
          if (error) {
            utils.setFieldValidationFlags(
              $.FORM_CURR.find("[name=code]"),
              error.message
            );
            if (window.mixpanel && window.mixpanel.track) {
              window.mixpanel.track(
                "Submit Login " + login.model.challengeReason + " Text failed",
                {
                  component: "Login",
                  error: error.message,
                }
              );
            }
            return;
          }
          if (login.model.challengeReason === "PWD_RESET") {
            login.showCreatePassword();
          } else if (login.model.challengeReason === "DEVICE_AUTH") {
            login.showDeviceAuthorized();
          } else if (login.model.challengeReason === "REQUIRES_VERIFICATION") {
            login.showDashboard(data && data.spHeader);
          }
        }
      );
      return false;
    });
    formChallengeResponseEmail.on("submit", function () {
      // eslint-disable-next-line no-invalid-this
      if (!utils.validateForm($(this))) return false;
      login.disableSubmitButton();
      PersonalCapital.services.Login.authenticateEmailCode(
        // eslint-disable-next-line no-invalid-this
        login.addDefaultPostData($(this).serializeObject()),
        function (data) {
          login.enableSubmitButton();
          var error = login.responseContainsErrors(data);
          if (error) {
            utils.setFieldValidationFlags(
              $.FORM_CURR.find("[name=code]"),
              error.message
            );
            if (window.mixpanel && window.mixpanel.track) {
              window.mixpanel.track(
                "Submit Login " + login.model.challengeReason + " Email failed",
                {
                  component: "Login",
                  error: error.message,
                }
              );
            }
            return;
          }
          if (login.model.challengeReason === "PWD_RESET") {
            login.showCreatePassword();
          } else if (login.model.challengeReason === "DEVICE_AUTH") {
            login.showDeviceAuthorized();
          } else if (login.model.challengeReason === "REQUIRES_VERIFICATION") {
            login.showDashboard(data && data.spHeader);
          }
        }
      );
      return false;
    });

    formChallengeResponseTotp.on("submit", function () {
      // eslint-disable-next-line no-invalid-this
      if (!utils.validateForm($(this))) return false;
      login.disableSubmitButton();
      // eslint-disable-next-line no-invalid-this
      var params = $(this).serializeObject();
      PersonalCapital.services.Login.authenticateTotpCode(
        login.addDefaultPostData(params),
        function (data) {
          login.enableSubmitButton();
          //Error handling
          var error = login.responseContainsErrors(data);
          if (error) {
            utils.setFieldValidationFlags(
              $.FORM_CURR.find("[name=totpCode]"),
              error.message
            );
            if (window.mixpanel && window.mixpanel.track) {
              window.mixpanel.track(
                "Submit Login " +
                  login.model.challengeReason +
                  " TOTP code failed",
                {
                  component: "Login",
                  error: error.message,
                }
              );
            }
            return;
          }

          //Success
          if (data.spHeader.success) {
            login.appAuthPolling.stop();
            // If the user used a recovery key (length > 6), show device unregistered message before anything else
            if (params.totpCode.length > TOTP_CODE_LENGTH) {
              if (login.model.challengeReason === "PWD_RESET") {
                login.showUnregisteredDevice(
                  // eslint-disable-next-line no-invalid-this
                  login.showCreatePassword.bind(this)
                );
              } else if (login.model.challengeReason === "DEVICE_AUTH") {
                login.showUnregisteredDevice(
                  // eslint-disable-next-line no-invalid-this
                  login.showDeviceAuthorized.bind(this)
                );
              } else if (
                login.model.challengeReason === "REQUIRES_VERIFICATION"
              ) {
                login.showUnregisteredDevice(
                  function () {
                    login.showDashboard(data && data.spHeader);
                    // eslint-disable-next-line
                  }.bind(this),
                  "Continue To Dashboard"
                );
              }
              return;
            }

            if (login.model.challengeReason === "PWD_RESET") {
              login.showCreatePassword();
            } else if (login.model.challengeReason === "DEVICE_AUTH") {
              login.showDeviceAuthorized();
            } else if (
              login.model.challengeReason === "REQUIRES_VERIFICATION"
            ) {
              login.showDashboard(data && data.spHeader);
            }
          }
        }
      );
      return false;
    });
    formChallengeResponsePhone.on("submit", function () {
      // eslint-disable-next-line no-invalid-this
      var form = $(this);
      login.phoneAuthPolling.start(
        // eslint-disable-next-line no-invalid-this
        login.addDefaultPostData($(this).serializeObject()),
        function (data) {
          var error = login.responseContainsErrors(data);
          if (error) {
            login.phoneAuthPolling.stop();
            form.find(".js-phone-challenge-polling-feedback").addClass("hide");
            form.find("label.controls.polling").hide();
            utils.setFormValidationFlags(
              $.FORM_CURR.find(".form-error"),
              error.message
            );
            if (window.mixpanel && window.mixpanel.track) {
              window.mixpanel.track(
                "Submit Login " + login.model.challengeReason + " Phone failed",
                {
                  component: "Login",
                  error: error.message,
                }
              );
            }
            return;
          }
          if (data.spHeader.success && data.spData && data.spData.pullStatus) {
            login.phoneAuthPolling.stop();
            if (login.model.challengeReason === "PWD_RESET") {
              login.showCreatePassword();
            } else if (login.model.challengeReason === "DEVICE_AUTH") {
              login.showDeviceAuthorized();
            }
          }
        },
        function () {
          login.phoneAuthPolling.stop();
          form.find(".js-phone-challenge-polling-feedback").addClass("hide");
          form.find("label.controls.polling").hide();
          utils.setFormValidationFlags(
            $.FORM_CURR.find(".form-error"),
            "We received no response from your phone."
          );
        }
      );
      return false;
    });
    login.onCreatePasswordSubmit = function () {
      // eslint-disable-next-line no-invalid-this
      if (!utils.validateForm($(this))) return false;
      try {
        /* eslint-disable no-invalid-this */
        if (
          $(this).find("[name=passwd]").val() !==
          $(this).find("[name=passwd2]").val()
        ) {
          /* eslint-enable no-invalid-this */
          utils.setFieldValidationFlags(
            $.FORM_CURR.find("[name=passwd2]"),
            utils.validationMessages.passwordMismatch
          );
          return false;
        }
      } catch (e) {
        return false;
      }
      if (login.model.challengeReason === "PWD_RESET") {
        login.disableSubmitButton();
        PersonalCapital.services.Login.resetPassword(
          // eslint-disable-next-line no-invalid-this
          login.addDefaultPostData($(this).serializeObject()),
          function (data) {
            login.enableSubmitButton();
            var error = login.responseContainsErrors(data);
            if (error) {
              utils.setFormValidationFlags(
                $.FORM_CURR.find(".form-error"),
                error.message
              );
              return;
            }
            $.FORM_CURR.find(".js-create-password-fields").hide();
            $.FORM_CURR.find(".pc-login--reset-password--success").show();
          }
        );
      } else if (login.model.challengeReason === "PWD_CREATE") {
        login.disableSubmitButton();
        PersonalCapital.services.Login.registerPassword(
          // eslint-disable-next-line no-invalid-this
          login.addDefaultPostData($(this).serializeObject()),
          function (data) {
            login.enableSubmitButton();
            var error = login.responseContainsErrors(data);
            if (error) {
              utils.setFormValidationFlags(
                $.FORM_CURR.find(".form-error"),
                error.message
              );
              return;
            }
            login.determineState(data);
          }
        );
      }
      return false;
    };
    formCreatePassword.on("submit", login.onCreatePasswordSubmit);
    formPin.on("submit", function () {
      // eslint-disable-next-line no-invalid-this
      if (!utils.validateForm($(this))) return false;
      login.disableSubmitButton();
      PersonalCapital.services.Login.authenticatePin(
        // eslint-disable-next-line no-invalid-this
        login.addDefaultPostData($(this).serializeObject()),
        function (data) {
          login.enableSubmitButton();
          var error = login.responseContainsErrors(data);
          if (error) {
            if (
              error.code === TWO_ZERO_SIX_ERROR ||
              error.code === THREE_TWO_ONE_ERROR
            )
              error.message = `You have locked out your PIN, please use your ${organizationNameDashboard} Mobile application to unlock your PIN.`;
            utils.setFieldValidationFlags(
              $.FORM_CURR.find("[name=pinNumber]"),
              error.message
            );
            return;
          }
          login.model.challengeReason = "PWD_CREATE";
          login.showCreatePassword();
        }
      );
      return false;
    });

    //bind form cancel & other click events
    $("a#forgotPassword").bind("click", function () {
      try {
        $("button.js-cancel").removeClass("DEVICE_AUTH");
        if (window.isForgotPasswordEnabled) {
          login.showChallengeResetPasswordEmailSent();
        } else if (window.userHasOnusAccounts) {
          //locked password reset credential
          login.showContactSupport(
            "Password reset attempt has exceeded the daily limit, please try again tomorrow. If this is urgent, contact your advisor."
          );
        } else {
          login.showContactSupport(
            "Password reset attempt has exceeded the daily limit, please try again tomorrow."
          );
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}
      return false;
    });
    $("a[name=challengeSMS]").bind("click", function () {
      try {
        // eslint-disable-next-line no-invalid-this, consistent-this
        var self = $(this);
        if (self.attr("disabled") === "disabled") return false;
        login.phoneAuthPolling.stop();
        login.appAuthPolling.stop();
        login.disableSubmitButton(self);
        PersonalCapital.services.Login.challengeSMS(
          login.addDefaultPostData({
            challengeReason: login.model.challengeReason,
            challengeMethod: "OP",
          }),
          function (data) {
            login.enableSubmitButton(self);
            var error = login.responseContainsErrors(data);
            if (error) {
              utils.setFormValidationFlags(
                $.FORM_CURR.find(".form-error"),
                error.message
              );
              return;
            }
            if (self.attr("class") === "resend") {
              $.FORM_CURR.find(".pc-help-block.error")
                .html("A new verification code was sent successfully")
                .hide()
                .show(ANIMATION_CONST);
            } else {
              login.showChallengeSMS();
            }
          }
        );
        // eslint-disable-next-line no-empty
      } catch (e) {}
      return false;
    });
    $("a[name=challengeEmail]").bind("click", function () {
      try {
        // eslint-disable-next-line no-invalid-this, consistent-this
        var self = $(this);
        if (self.attr("disabled") === "disabled") return false;
        login.phoneAuthPolling.stop();
        login.appAuthPolling.stop();
        login.disableSubmitButton(self);
        PersonalCapital.services.Login.challengeEmail(
          login.addDefaultPostData({
            challengeReason: login.model.challengeReason,
            challengeMethod: "OP",
          }),
          function (data) {
            login.enableSubmitButton(self);
            var error = login.responseContainsErrors(data);
            if (error) {
              utils.setFormValidationFlags(
                $.FORM_CURR.find(".form-error"),
                error.message
              );
              return;
            }
            if (self.attr("class") === "resend") {
              $.FORM_CURR.find(".pc-help-block.error")
                .html("A new verification email was sent successfully")
                .hide()
                .show(ANIMATION_CONST);
            } else {
              login.showChallengeEmail();
            }
          }
        );
        // eslint-disable-next-line no-empty
      } catch (e) {}
      return false;
    });
    $("a[name=challengePhone]").bind("click", function () {
      try {
        // eslint-disable-next-line no-invalid-this, consistent-this
        var self = $(this);
        if (self.attr("disabled") === "disabled") return false;
        login.phoneAuthPolling.stop();
        login.appAuthPolling.stop();
        login.disableSubmitButton(self);
        PersonalCapital.services.Login.challengePhone(
          login.addDefaultPostData({
            challengeReason: login.model.challengeReason,
            challengeMethod: "OP",
          }),
          function (data) {
            login.enableSubmitButton(self);
            var error = login.responseContainsErrors(data);
            if (error) {
              utils.setFormValidationFlags(
                $.FORM_CURR.find(".form-error"),
                error.message
              );
              return;
            }
            if (self.attr("class") === "resend") {
              $.FORM_CURR.find(".form-error").html("");
              $.FORM_CURR.find(".controls.polling")
                .hide()
                .show(ANIMATION_CONST);
              $.FORM_CURR.submit();
            } else {
              login.showChallengePhone();
            }
          }
        );
        // eslint-disable-next-line no-empty
      } catch (e) {}
      return false;
    });

    $("a.js-use-totp-link").bind("click", function () {
      login.phoneAuthPolling.stop();
      // eslint-disable-next-line no-invalid-this
      if ($(this).attr("disabled") === "disabled") return false;
      // eslint-disable-next-line no-invalid-this
      const params = $(this).serializeObject();
      params.challengeMethod = "TP";
      params.challengeReason = login.model.challengeReason;
      delete params.totpCode;
      PersonalCapital.services.Login.challengeTotp(
        login.addDefaultPostData(params),
        function (data) {
          var error = login.responseContainsErrors(data);
          if (error) {
            utils.setFormValidationFlags(
              $.FORM_CURR.find(".form-error"),
              error.message
            );
            return;
          }
          if (data.spData && data.spData.pushEnabled) {
            login.showChallengeTotp(data);
          } else {
            login.showEnterTotpCodeFields(data);
          }
        }
      );
    });

    $(".js-enter-totp-code").bind("click", function () {
      login.showEnterTotpCodeFields();
    });
    $("input[name=bindDevice]").change(function () {
      // eslint-disable-next-line no-invalid-this
      if ($(this).is(":checked")) {
        // eslint-disable-next-line no-invalid-this
        $(this).val("true");
        $(".control-group.deviceNameInput").show();
      } else {
        // eslint-disable-next-line no-invalid-this
        $(this).val("false");
        $(".control-group.deviceNameInput").hide();
      }
    });
    $("button.resetUser").bind("click", function () {
      try {
        login.switchUser();
        //eslint-disable-next-line no-empty
      } catch (e) {}
      return false;
    });
    $("body").on("click", "button.js-cancel.DEVICE_AUTH", function () {
      try {
        login.phoneAuthPolling.stop();
        // eslint-disable-next-line no-invalid-this
        $(this).removeClass("DEVICE_AUTH");
        login.switchUser();
        //eslint-disable-next-line no-empty
      } catch (e) {}
      return false;
    });
    $("body").on("click", "button.js-cancel.PWD_RESET", function () {
      try {
        login.phoneAuthPolling.stop();
        // eslint-disable-next-line no-invalid-this
        $(this).removeClass("PWD_RESET");
        if (login.model.status === "LOCKED") {
          login.switchUser();
        } else {
          login.showPassword({
            username: window.username,
            deviceName: window.deviceName,
          });
        }
        //eslint-disable-next-line no-empty
      } catch (e) {}
      return false;
    });
    $("body").on(
      "click",
      "button.js-cancel-challenge-password-reset-email-sent, button.js-cancel--contact-support",
      function () {
        try {
          if (login.model.status === "LOCKED") {
            login.switchUser();
          } else {
            login.showPassword({});
          }
          //eslint-disable-next-line no-empty
        } catch (e) {}
        return false;
      }
    );
    $("body").on(
      "click",
      "#form-requiresVerification button.js-cancel",
      function (e) {
        e.preventDefault();
        login.cancelRequiresVerification();
        return false;
      }
    );
    $("body").on(
      "click",
      "button.js-cancel.REQUIRES_VERIFICATION",
      function (e) {
        e.preventDefault();
        login.cancelRequiresVerification();
        return false;
      }
    );
    //bind form controls validation
    $("input.validate.blur").blur(function () {
      // eslint-disable-next-line no-invalid-this
      if ($(this).val().length > 0) utils.validateField($(this));
    });
    $("input.validate").keyup(function (e) {
      //TODO: consider only valid key strokes
      if (e.keyCode === ENTER_KEY) return;
      // eslint-disable-next-line no-invalid-this
      utils.clearValidationFlags($(this));
    });
  };
  return login;
})(views.LOGIN, jQuery); //exporting login module to global namespace:windows.personalcapital

/*
 * used to serialize form controls into a json object
 * jquery's serializeArray returns an array object rather than a json object
 * and that makes it difficult to alter the object if need be.
 * the following extends serializeArray and returns json object
 * taken from the following link: http://stackoverflow.com/questions/1184624/convert-form-data-to-js-object-with-jquery
 *
 * if we do have complex forms we should use form2js library which also provides for nested json object
 */
jQuery.fn.serializeObject = function () {
  var o = {};
  var a = this.serializeArray();
  $.each(a, function () {
    /* eslint-disable no-invalid-this */
    if (o[this.name] === undefined) {
      o[this.name] = this.value || "";
    } else {
      if (!o[this.name].push) {
        o[this.name] = [o[this.name]];
      }
      o[this.name].push(this.value || "");
    }
    /* eslint-enable no-invalid-this */
  });
  return o;
};
/*
 * extending jquery's show function for forms with the following:
 * setting form data, setting form as current, hiding previous and showing current form
 * TODO:
 * 		extending this for any views in general
 * 		namespacing properties
 * 		setting data for all controls
 */
//TODO: Adding a namespace should prevent with name conflicts
jQuery.fn.FORM_CURR = null;
jQuery.fn.FORM_PREV = null;
jQuery.fn.showForm = function (data) {
  //set form data
  if (data) {
    //input controls
    this.find(
      "input[type=text],input[type=hidden],input[type=password],input[type=checkbox]"
    ).val(function (index, value) {
      //eslint-disable-next-line no-invalid-this
      var val = data[this.name];
      if (val === undefined) val = value;
      return val;
    });
    //label controls
    this.find("label.dynamic, div.dynamic").html(function (index, value) {
      // eslint-disable-next-line no-invalid-this
      var val = data[$(this).attr("for")];
      if (val === undefined) val = value;
      if (val) return val;
    });
    //legend
    this.find("div.legend").html(function (index, value) {
      var val = data.legend;
      if (val === undefined) val = value;
      if (val) return val;
    });
  }
  //set form as current form
  $.FORM_PREV = $.FORM_CURR;
  $.FORM_CURR = this;
  //hide previous form
  if ($.FORM_PREV) $.FORM_PREV.hide();
  //clear any validation flags from previous instance
  utils.clearFormValidationFlags($.FORM_CURR);
  //show current form
  $.FORM_CURR.show();
  //set focus
  $.FORM_CURR.find("[autofocus]").focus().select();
};
/*
 * form validation
 * we should think about using a validation library such as
 * http://docs.jquery.com/Plugins/Validation
 */
utils.validateForm = function (form) {
  var isValid = true;
  try {
    form.find("input.validate").each(function () {
      // eslint-disable-next-line no-invalid-this
      isValid = utils.validateField($(this)) && isValid;
    });
    return isValid;
  } catch (e) {
    utils.logException(e);
    return false;
  }
};
utils.validateField = function (field) {
  var isValid = false;
  try {
    var fName = field.attr("name"),
      fVal = field.val();
    //validate the field
    switch (fName) {
      default: {
        //"username"
        isValid =
          fVal.length <= USER_NAME_LENGTH &&
          fVal.indexOf("'") === -1 &&
          fVal.indexOf('"') === -1 &&
          utils.regex.email.test(fVal.toLowerCase());
        break;
      }
      case "passwd":
      case "passwd2": {
        isValid =
          fVal.length >= PASSWORD_MIN_LENGTH &&
          fVal.length <= PASSWORD_MAX_LENGTH;
        break;
      }
      case "code": {
        isValid = utils.regex.code.test(fVal);
        break;
      }
      case "totpCode": {
        isValid = utils.regex.totpCode.test(fVal);
        break;
      }
      case "PASSWORD": {
        isValid = fVal.length > 0;
        break;
      }
      case "pinNumber": {
        isValid = utils.regex.pinNumber.test(fVal);
        break;
      }
      case "deviceName": {
        isValid = field.is(":visible") ? fVal.length > 0 : true;
        break;
      }
    }
    //style the field accordingly
    if (isValid) {
      utils.clearValidationFlags(field);
    } else {
      utils.setFieldValidationFlags(field, utils.validationMessages[fName]);
    }
    return isValid;
  } catch (e) {
    utils.logException(e);
    return false;
  }
};
utils.clearValidationFlags = function (field) {
  try {
    field.siblings(".pc-help-block.error").html("").hide();
    field.closest(".control-group.error").removeClass("error");
    field.siblings(".pc-help-block.helpText").show();
  } catch (e) {
    utils.logException(e);
    return false;
  }
};
utils.clearFormValidationFlags = function (form) {
  try {
    form.find(".pc-help-block.error").html("");
    form.find(".form-error").html("");
    form.find(".control-group.error").removeClass("error");
    form.find(".pc-help-block.helpText").show();
  } catch (e) {
    utils.logException(e);
    return false;
  }
};
utils.setFormValidationFlags = function (field, msg) {
  try {
    field.html(msg);
  } catch (e) {
    utils.logException(e);
    return false;
  }
};
utils.setFieldValidationFlags = function (field, msg) {
  try {
    field.siblings(".pc-help-block.helpText").hide();
    field.siblings(".pc-help-block.error").html(msg).show();
    field.closest(".control-group").addClass("error");
  } catch (e) {
    utils.logException(e);
    return false;
  }
};
utils.validationMessages = {
  username: "Must be a valid email address, 50 characters or less.",
  passwd: "Must be 8-64 characters.",
  passwd2: "Must be 8-64 characters.",
  phone: "Must be a valid 10 digit phone number.",
  code: "Must be a four digit number",
  totpCode: "Must be a six digit number",
  pinNumber: "Must be a six digit number",
  passwordMismatch: "Password mis-match",
  inactiveUsername:
    'Username not found. Check spelling or <a  class="pc-btn--link inactiveUserLink" href="/page/login/registerUser">sign up.</a>',
  lockedPIN: `You have locked out your PIN, please use your ${EMPOWER_PERSONAL_DASHBOARD} Mobile application to unlock your PIN.`,
  deviceName: "Device Name is required",
};
utils.regex = {
  email: /^[A-Za-z0-9._%+-]+@(([0-9a-z][-\w]*[0-9a-z]*\.)+[A-Za-z]{2,10})$/,
  simpleEmail: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
  phone: /^[\d]{3}[-. ]?[\d]{3}[-. ]?[\d]{4}$/,
  code: /^[\d]{4}$/,
  totpCode:
    /^[\d]{6}$|^[a-zA-Z0-9]{16}$|^[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}$/,
  pinNumber: /^[\d]{6}$/,
};
utils.log = function (msg) {
  if (window.baseUrl === "https://home.personalcapital.com") return;
  if (console && console.log) console.log(msg);
};
utils.logException = function (e) {
  var msg = "exception: " + e.message;
  if (e.lineNumber) msg += " lineNumber: " + e.lineNumber;
  if (e.fileName) msg += " fileName: " + e.fileName;
  utils.log(msg);
};
/* eslint-disable no-empty-function */
utils.deviceFSO = {
  obj: null,
  init: function () {},
  set: function () {},
  get: function () {},
  ready: function () {},
};
/* eslint-enable no-empty-function */

/*
 * http://www.quirksmode.org/js/detect.html
 */
var BrowserDetect = {
  init: function () {
    this.browser = this.searchString(this.dataBrowser) || "An unknown browser";
    this.version =
      this.searchVersion(navigator.userAgent) ||
      this.searchVersion(navigator.appVersion) ||
      "an unknown version";
    this.OS = this.searchString(this.dataOS) || "an unknown OS";
  },
  searchString: function (data) {
    for (var i = 0; i < data.length; i++) {
      var dataString = data[i].string;
      var dataProp = data[i].prop;
      this.versionSearchString = data[i].versionSearch || data[i].identity;
      if (dataString) {
        if (dataString.indexOf(data[i].subString) !== -1)
          return data[i].identity;
      } else if (dataProp) return data[i].identity;
    }
  },
  searchVersion: function (dataString) {
    var index = dataString.indexOf(this.versionSearchString);
    if (index === -1) return;
    return parseFloat(
      dataString.substring(index + this.versionSearchString.length + 1)
    );
  },
  dataBrowser: [
    {
      string: navigator.userAgent,
      subString: "Chrome",
      identity: "Chrome",
    },
    {
      string: navigator.userAgent,
      subString: "OmniWeb",
      versionSearch: "OmniWeb/",
      identity: "OmniWeb",
    },
    {
      string: navigator.vendor,
      subString: "Apple",
      identity: "Safari",
      versionSearch: "Version",
    },
    {
      prop: window.opera,
      identity: "Opera",
      versionSearch: "Version",
    },
    {
      string: navigator.vendor,
      subString: "iCab",
      identity: "iCab",
    },
    {
      string: navigator.vendor,
      subString: "KDE",
      identity: "Konqueror",
    },
    {
      string: navigator.userAgent,
      subString: "Firefox",
      identity: "Firefox",
    },
    {
      string: navigator.vendor,
      subString: "Camino",
      identity: "Camino",
    },
    {
      // for newer Netscapes (6+)
      string: navigator.userAgent,
      subString: "Netscape",
      identity: "Netscape",
    },
    {
      string: navigator.userAgent,
      subString: "MSIE",
      identity: "Explorer",
      versionSearch: "MSIE",
    },
    {
      string: navigator.userAgent,
      subString: "Gecko",
      identity: "Mozilla",
      versionSearch: "rv",
    },
    {
      // for older Netscapes (4-)
      string: navigator.userAgent,
      subString: "Mozilla",
      identity: "Netscape",
      versionSearch: "Mozilla",
    },
  ],
  dataOS: [
    {
      string: navigator.platform,
      subString: "Win",
      identity: "Windows",
    },
    {
      string: navigator.platform,
      subString: "Mac",
      identity: "Mac",
    },
    {
      string: navigator.userAgent,
      subString: "iPhone",
      identity: "iPhone/iPod",
    },
    {
      string: navigator.platform,
      subString: "Linux",
      identity: "Linux",
    },
  ],
};
BrowserDetect.init();

LOGIN.urlParams = new URLSearchParams(window.location.search);
let loginInitParams = {
  spHeader: {
    status: window.status,
    authLevel: window.authLevel,
    accountsSummary: {
      hasOnUs: window.hasOnUs,
    },
    hasAggregated: window.hasAggregated,
  },
  spData: {
    username: window.username,
    deviceName: window.deviceName,
    allCredentials: window.allCredentials || [],
  },
};

LOGIN.setForgotPasswordFeatureFlag(window.allCredentials);

LOGIN.init(loginInitParams);

if (LOGIN.urlParams.has("token")) {
  LOGIN.verifyResetToken(loginInitParams, LOGIN.urlParams.get("token"));
}

let loginContainerElement = document.getElementById("loginContainer");
if (loginContainerElement) {
  loginContainerElement.classList.remove("loading");
}

export default LOGIN;
