import $ from "jquery";
let PersonalCapital = {
  services: {
    request: function (url, params, callback) {
      params = params || {};
      params.csrf = window.csrf;

      let reqUrl;
      let strippedBaseUrl;

      // if baseUrl ends with a backslash, remove it
      if (/\/$/.test(window.baseUrl)) {
        strippedBaseUrl = window.baseUrl.substr(0, baseUrl.length - 1);
      } else {
        strippedBaseUrl = window.baseUrl;
      }
      // prefix request url with base url
      reqUrl = strippedBaseUrl + url;

      /*PFA-5994: Remove flash cookie: DEVISE_FSO
       *
        if(typeof PersonalCapital.globals.DEVICE_FSO != "undefined")params.DEVICE_FSO = PersonalCapital.globals.DEVICE_FSO;
      */
      //TODO: params.apiClient should be set at the caller level since we have more than one client: sidebar, login, web, plugin etc.
      //		if any, the default should be more generic one: web
      if (!params.apiClient) params.apiClient = "WEB";

      $.post(reqUrl, params, "json").success(function (response) {
        //If csrf is returned as part of response, it should update window.csrf
        if (response && response.spHeader && response.spHeader.csrf) {
          window.csrf = response.spHeader.csrf;
        }
        /*PFA-5994: Remove flash cookie: DEVISE_FSO
         *
          //If deviceFSO is returned as part of response, it should set the flash cookie and update globals.DEVICE_FSO
          if(response && response.spHeader && response.spHeader.DEVICE_FSO){
            PersonalCapital.utils.deviceFSO.set(response.spHeader.DEVICE_FSO);
            PersonalCapital.globals.DEVICE_FSO = PersonalCapital.utils.deviceFSO.get();
          }
        */
        if (typeof callback == "function") {
          callback(response);
        }
      });
    },
    Accounts: {
      get: function (params, callback) {
        if (typeof params == "function") {
          callback = params;
          params = null;
        }
        PersonalCapital.services.request(
          "/api/newaccount/getAccounts",
          params,
          callback
        );
      },
    },
    Session: {
      get: function (params, callback) {
        if (typeof params == "function") {
          callback = params;
          params = null;
        }
        PersonalCapital.services.request(
          "/api/login/querySession",
          params,
          callback
        );
      },
    },
    feedback: {
      send: function (title, description, callback) {
        /* Previous implementation */ // eslint-disable-next-line no-use-before-define
        var params = params || {};
        params.description = title + ": " + description;
        PersonalCapital.services.request(
          "/api/feedback/logFeedback",
          params,
          callback
        );
      },
    },
    InvestmentProfile: {
      get: function (callback) {
        PersonalCapital.services.request(
          "/api/profile/getAdvisorInterviewInfo",
          null,
          callback
        );
      },

      update: function (params, callback) {
        PersonalCapital.services.request(
          "/api/profile/updateAdvisorInterviewInfo",
          params,
          callback
        );
      },
    },
    Login: {
      validateSession: function (params, callback) {
        PersonalCapital.services.request(
          "/api/login/validateSession",
          params,
          callback
        );
      },

      identifyUser: function (params, callback) {
        PersonalCapital.services.request(
          "/api/login/identifyUser",
          params,
          callback
        );
      },

      challengeResetPassword: function (params, callback) {
        PersonalCapital.services.request(
          "/api/credential/challengeResetPassword",
          params,
          callback
        );
      },

      authenticatePassword: function (params, callback) {
        PersonalCapital.services.request(
          "/api/credential/authenticatePassword",
          params,
          callback
        );
      },

      authenticatePin: function (params, callback) {
        PersonalCapital.services.request(
          "/api/credential/authenticatePin",
          params,
          callback
        );
      },

      authenticateSMS: function (params, callback) {
        PersonalCapital.services.request(
          "/api/credential/authenticateSms",
          params,
          callback
        );
      },

      authenticateEmailCode: function (params, callback) {
        PersonalCapital.services.request(
          "/api/credential/authenticateEmailByCode",
          params,
          callback
        );
      },

      authenticatePhoneAuth: function (params, callback) {
        PersonalCapital.services.request(
          "/api/credential/authenticatePhone",
          params,
          callback
        );
      },

      authenticateResetPasswordToken: function (params, callback) {
        PersonalCapital.services.request(
          "/api/credential/authenticateResetPasswordToken",
          params,
          callback
        );
      },

      authenticateTotpAuth: function (params, callback) {
        PersonalCapital.services.request(
          "/api/credential/authenticateTotpPush",
          params,
          callback
        );
      },

      authenticateTotpCode: function (params, callback) {
        PersonalCapital.services.request(
          "/api/credential/authenticateTotpCode",
          params,
          callback
        );
      },

      registerPassword: function (params, callback) {
        PersonalCapital.services.request(
          "/api/credential/registerCredentials",
          params,
          callback
        );
      },

      resetPassword: function (params, callback) {
        PersonalCapital.services.request(
          "/api/credential/resetPassword",
          params,
          callback
        );
      },

      challengeEmail: function (params, callback) {
        PersonalCapital.services.request(
          "/api/credential/challengeEmail",
          params,
          callback
        );
      },

      challengeSMS: function (params, callback) {
        PersonalCapital.services.request(
          "/api/credential/challengeSms",
          params,
          callback
        );
      },

      challengePhone: function (params, callback) {
        PersonalCapital.services.request(
          "/api/credential/challengePhone",
          params,
          callback
        );
      },

      challengeTotp: function (params, callback) {
        PersonalCapital.services.request(
          "/api/credential/challengeTotp",
          params,
          callback
        );
      },

      switchUser: function (params, callback) {
        PersonalCapital.services.request(
          "/api/login/switchUser",
          params,
          callback
        );
      },

      suggestDeviceName: function (params, callback) {
        PersonalCapital.services.request(
          "/api/credential/suggestDeviceName",
          params,
          callback
        );
      },
    },
  },
};

$(function () {
  // capture the elusive csrf
  if (!window.csrf && typeof csrf != "undefined") {
    window.csrf = csrf;
  }
});

export default PersonalCapital;
